import styled from "styled-components"

export const Button = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;

  & .error {
    color: var(--error);
  }
`
