import React from "react"
import { Link } from "gatsby"
import { fmtCurrency } from "../../../../utils/helpers"
import Select from "../../../form/QtySelect"
import { Table, ItemContainer, ExtrasContainer } from "./styled"
import ButtonIcon from "../../../ButtonIcon"
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons"

const CartTable = ({ items, update }) => {
  let total = 0
  let subTotal = 0

  return (
    <Table>
      <thead>
        <tr>
          <th className="qty">Qty</th>
          <th className="left">Item</th>
          <th className="mobileHide">Price</th>
          <th className="mobileHide">Amount</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          let price = item.price
          subTotal = item.qty * item.price
          total = total + subTotal

          let id = ""
          if (item.product === "system") {
            id = item.systemId
          } else if (item.product === "filter") {
            id = item.filterId
          }
          return (
            <tr key={index}>
              <td className="center">
                <Select
                  product={item.product}
                  id={id}
                  cartIndex={index}
                  value={item.qty}
                  options={[
                    { value: "1", name: "1" },
                    { value: "2", name: "2" },
                    { value: "3", name: "3" },
                    { value: "4", name: "4" },
                    { value: "5", name: "5" },
                  ]}
                  change={update}
                />
              </td>
              <td>
                <ItemContainer>
                  <div>
                    <span>{`${item.item}`}</span>
                    <small>{`${item.subitem}`}</small>
                  </div>
                  <ButtonIcon
                    icon={faTrashAlt}
                    className="error"
                    click={update}
                    product={item.product}
                    id={id}
                    cartIndex={index}
                  />
                </ItemContainer>

                <ExtrasContainer
                  className={
                    item.product === "filter" ? "mobileShow" : "hidden"
                  }
                >
                  <Table>
                    <tbody>
                      <tr>
                        <td>Product</td>
                        <td className="right">{fmtCurrency(price)}</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </ExtrasContainer>

                <ExtrasContainer
                  className={
                    !item.extras || item.extras.length === 0 ? "hidden" : ""
                  }
                >
                  <Table>
                    <tbody>
                      <tr key={99}>
                        <td>Equipment</td>
                        <td className="right">{fmtCurrency(price)}</td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody>
                      {item.extras &&
                        item.extras.map((extra, indexExtra) => {
                          price = price + extra.price
                          subTotal = subTotal + item.qty * extra.price
                          total = total + item.qty * extra.price

                          return (
                            <tr
                              key={indexExtra + 100}
                              className={!extra.name ? "hidden" : ""}
                            >
                              <td>
                                {extra.type === "thermostat" ? (
                                  <Link to={`/thermostats?cart=${index}`}>
                                    {extra.name}
                                  </Link>
                                ) : (
                                  extra.name
                                )}
                              </td>
                              <td className="right">
                                {fmtCurrency(extra.price)}
                              </td>
                              <td className="mobileHide">
                                {extra.type === "thermostat" ? (
                                  <span>
                                    [{" "}
                                    <Link to={`/thermostats?cart=${index}`}>
                                      change
                                    </Link>{" "}
                                    ]
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </ExtrasContainer>
              </td>
              <td className="right mobileHide">{fmtCurrency(price)}</td>
              <td className="right mobileHide">{fmtCurrency(subTotal)}</td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr className="mobileHide">
          <th colSpan="3" className="right ">
            TOTAL
          </th>
          <td className="right">{fmtCurrency(total)}</td>
        </tr>
        <tr className="mobileShow">
          <td colSpan="2" className="right">
            <strong style={{ paddingRight: "7.5px" }}>TOTAL:</strong>{" "}
            {fmtCurrency(total)}
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default CartTable
