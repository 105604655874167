import React, { useState, useEffect } from "react"
import { getCart, setCart } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Alert from "../components/Alert"
import CartTable from "../components/content/Cart/CartTable"
import ButtonLink from "../components/ButtonLink"
import { Main, MainContainer, Grid } from "../components/content/Cart/styled"

const Cart = () => {
  const [items, setItems] = useState(getCart())
  const [alert, setAlert] = useState({})

  useEffect(() => {
    if (items.length === 0) {
      setAlert({
        type: "notice",
        text: "Your cart is currently empty.",
      })
    }
  }, [items])

  const handleChange = (product, id, cartIndex, value) => {
    let array = [...items]
    array.forEach((item, index) => {
      if (item.product === product && index === cartIndex) {
        if (item.product === "system" && item.systemId === id) {
          if (value > 0) {
            item.qty = parseInt(value, 10)
          } else {
            array.splice(index, 1)
          }
        } else if (item.product === "filter" && item.filterId === id) {
          if (parseInt(value, 10) > 0) {
            item.qty = parseInt(value, 10)
          } else {
            array.splice(index, 1)
          }
        }
      }
    })
    setCart(array)
    setItems(array)
  }

  return (
    <Layout>
      <Helmet
        name="Shopping Cart"
        description="Items currently in your shopping cart."
        slug="Cart"
      />
      <Header />
      <PageHdr title="Shopping Cart" />
      <section>
        <Main>
          <MainContainer className="main-page">
            {items.length > 0 ? (
              <article>
                <h2>Items currently in your cart.</h2>
                <Grid>
                  <CartTable items={items} update={handleChange} />

                  <div className="btns">
                    <ButtonLink to="/buy/1" name="Shop for AC Units" />
                    <ButtonLink to="/filters" name="Shop for Filters" />
                    <ButtonLink to="/address" name="Checkout" />
                  </div>
                </Grid>
              </article>
            ) : (
              <article>
                <Grid>
                  <Alert data={alert} />

                  <div className="btns">
                    <ButtonLink to="/buy/1" name="Shop for AC Units" />
                    <ButtonLink to="/filters" name="Shop for Filters" />
                  </div>
                </Grid>
              </article>
            )}
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Cart
