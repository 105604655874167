import styled from "styled-components"

import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
  Grid as BaseGrid,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)`
  @media screen and (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }

  & > article {
    width: 100%;
  }
`

export const Grid = styled(BaseGrid)`
  width: 100%;
  grid-template-columns: 1fr;

  & > div.btns {
    margin-top: 30px;
    border: 1px solid #ddd;
    padding: 15px;
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    & > a:nth-child(2) {
      margin-left: 15px;
      margin-right: 15px;

      @media screen and (max-width: 767px) {
        margin: 0;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
`
