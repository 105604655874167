import React from "react"
import { Button } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ButtonIcon = ({
  icon,
  className,
  click,
  product = "",
  id = "0",
  cartIndex = 999,
  value = 0,
}) => {
  return (
    <Button type="button" onClick={e => click(product, id, cartIndex, value)}>
      <FontAwesomeIcon icon={icon} className={className} />
    </Button>
  )
}

export default ButtonIcon
