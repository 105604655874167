import React from "react"
import { Element } from "./styled"

const QtySelect = ({ product, id, cartIndex, value, options, change }) => {
  return (
    <Element
      onChange={e => change(product, id, cartIndex, e.target.value)}
      defaultValue={value}
    >
      {options.map(item => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </Element>
  )
}

export default QtySelect
