import styled from "styled-components"

import { Table as BaseTable } from "../../../../elements/Layout/styled"

export const Table = styled(BaseTable)`
  margin-top: 15px;

  & th.qty {
    width: 10%;

    @media (max-width: 1023px) {
      width: 12%;
    }

    @media (max-width: 600px) {
      width: 20%;
    }
  }

  & tr.hidden {
    display: none;
  }

  & tr.mobileHide,
  & th.mobileHide,
  & td.mobileHide {
    @media (max-width: 767px) {
      display: none;
    }
  }

  & tr.mobileShow {
    display: none;

    @media (max-width: 767px) {
      display: table-row;
    }
  }
`
export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;

  & > div {
    display: flex;
    flex-direction: column;
    text-align: left;

    & > span {
      font-weight: 600;
    }
  }
`

export const ExtrasContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  &.mobileShow {
    display: none;

    @media screen and (max-width: 767px) {
      display: flex;
    }
  }

  &.hidden {
    display: none;
  }

  & > table {
    width: auto;
    margin: 0;
    margin-top: 7.5px;

    & td {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 15px;
      text-align: left;
      border: none;
      font-size: 0.85rem;
    }
  }
`
