import styled from "styled-components"
import { Select } from "../../../elements/Layout/styled"

export const Element = styled(Select)`
  width: 60%;
  font-family: arial, sans-serif;

  @media (max-width: 767px) {
    width: 100%;
  }
`
